import Vue from "vue";

/*TITLE*/
document.title = "Residencial RYF | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Residencial RYF";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Residencial RYF";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-forest-natural--20230126110105.jpg";
Vue.prototype.$image_kitchen = "cocina-forest-natural-22x90-glaciar-316x90-frente-blanco-nubol--20230126110113.jpg";
Vue.prototype.$image_bath1 = "banop-forest-natural-22x90-bianco-carrara-316x90--20230126110123.jpg";
Vue.prototype.$image_bath2 = "";
Vue.prototype.$image_room = "habp-forest-natural--20230126110132.jpg";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-forest-natural--20230126110105.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-forest-natural-22x90-glaciar-316x90-frente-blanco-nubol--20230126110113.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-forest-natural-22x90-bianco-carrara-316x90--20230126110123.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
];

Vue.prototype.$gallery_images_room = [
  {
    src: "habp-forest-natural--20230126110132.jpg",
  },
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "6";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "6";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://clientes.ppgstudios.com/porcelanosa/ryf/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://player.vimeo.com/video/784501494";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=fernando%20guanarteme%2092%20las%20palmas%2035010%20",
    text: "Fernando Guanarteme 92, Las Palmas (35010)",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=las%20palmas%2035010%20",
    text: "Las Palmas (35010)",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:672692149",
    text: "672692149",
  },
  {
    icon: "mdi-email",
    link: "mailto:laspalmas@gilmar.es",
    text: "laspalmas@gilmar.es",
  },
];
